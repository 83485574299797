import React from "react";
import LeftPhoto from "./LeftPhoto";
import RightPhoto from "./RightPhoto";

import moviedesc from "../images/description.png";
import tvshow from "../images/tvshow.png";
import search from "../images/search.png";

const Features = () => {
  return (
    <div>
      <div id="movie">
        <LeftPhoto
          image={moviedesc}
          title="Movie Detail"
          description="Get the trending movies for this week or for the day.Quickly look at the genres and the details of the movie. Able to quickly visit the movie's imdb page for more details."
        />
      </div>
      <div id="tvshow">
        <RightPhoto
          image={tvshow}
          title="TV show"
          description="Get the trending TV shows for this week or for the day.Quickly look at the genres and the details of the TV show. Able to quickly visit the TV show's page for more details."
        />
      </div>
      <div id="search">
        <LeftPhoto
          image={search}
          title="Search results"
          description="Looking for the old movies or TV shows? Search for the movie or TV show you are looking for easily."
        />
      </div>
    </div>
  );
};

export default Features;
