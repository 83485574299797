import Panda from "../images/panda.jpeg";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Uninstall = () => {
  return (
    <>
      <Navbar />
      <section className="terms-section max-w-7xl px-1 m-auto py-10 text-left flow-content">
        <div className="flex flex-col h-screen justify-between">
          <main className="mt-10 flex flex-col  items-center">
            <div className="w-full flex flex-col items-center">
              <div className="text-4xl font-bold font-Montserrat ">
                <h1>We are sad to see you leave</h1>
              </div>
              <div className="text-xl font-bold text-center font-Montserrat ">
                <h1>We hope to see you again soon!</h1>
              </div>
            </div>
            <div className="mt-40 mb-40">
              <img src={Panda} alt="people leaving" />
            </div>
          </main>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Uninstall;
