import TV from "../images/tv.svg";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Install = () => {
  return (
    <>
      <Navbar />
      <section className="terms-section max-w-7xl px-1 m-auto py-10 text-left flow-content">
        <div className="flex flex-col h-screen justify-between">
          <main className="mt-10 flex flex-col items-center">
            <div className="w-full flex flex-col items-center">
              <div className="goodbye font-medium font-Montserrat ">
                <h1 className="text-4xl font-bold text-center">
                  Thank you for installing Piezo Run extension
                </h1>
              </div>
              <div className="goodbye font-medium font-Montserrat mt-4">
                <h1 className="text-xl font-bold text-center">
                  Quickly find movies and tv shows. Let the fun begin!
                </h1>
              </div>
            </div>
            <div className="mt-10">
              <img src={TV} alt="people leaving" width={300} height="auto" />
            </div>
          </main>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Install;
