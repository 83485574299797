import React from "react";

const Footer = () => {
  return (
    <footer className="w-full bg-gray-800 text-white pt-12 pb-12 tracking-wide">
      <div className="max-w-7xl pl-5 pr-5 m-auto grid grid-cols-3">
        <div className="col-span-2 text-left">
          <h3 className="uppercase text-xl font-medium">
            Copyright ©Imali Media LTD {new Date().getFullYear()}
          </h3>
          <p className="pt-2">All Rights Reserved, powered by Yahoo</p>
        </div>
        {/* <div className="col-span-1  flex flex-wrap">
          <div className="w-full">
            <h3 className="uppercase text-2xl font-medium w-full">
              made for your browser
            </h3>
          </div>
          <div className="w-full">
            <ul className="flex w-3/4 m-auto mt-4 justify-center">
              <li className=" ml-2 mr-2">
                <div className="footer_link">
                  <img
                    src="https://pics.freeicons.io/uploads/icons/png/805147571548141938-512.png"
                    alt=""
                  />
                </div>
              </li>
              <li className=" ml-2 mr-2">
                <div className="footer_link">
                  <img
                    src="https://pics.freeicons.io/uploads/icons/png/805147571548141938-512.png"
                    alt=""
                  />
                </div>
              </li>
              <li className=" ml-2 mr-2">
                <div className="footer_link">
                  <img
                    src="https://pics.freeicons.io/uploads/icons/png/805147571548141938-512.png"
                    alt=""
                  />
                </div>
              </li>
              <li className=" ml-2 mr-2">
                <div className="footer_link">
                  <img
                    src="https://pics.freeicons.io/uploads/icons/png/805147571548141938-512.png"
                    alt=""
                  />
                </div>
              </li>
            </ul>
          </div>
        </div> */}
        <div className="col-span-1 flex justify-end">
          <div className="text-left">
            <h3 className="uppercase text-xl font-medium">Contact</h3>
            <div className="pt-2">
              Email:{" "}
              <a
                href="mailto:support@imali.media"
                target="_blank"
                rel="noreferrer"
              >
                support@imali.media
              </a>
              <div>Address: Tel-Aviv, Israel 6971006</div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
